import React, {useState} from "react";
import { useNavigate } from "react-router-dom";

import foodie1 from "../../assets/portfolio/Foodie-1.PNG";
import foodie2 from "../../assets/portfolio/Foodie-2.PNG";
import Iviry1 from "../../assets/portfolio/Iviry-1.PNG";
import Iviry2 from "../../assets/portfolio/Iviry-2.PNG";
import matchplicity1 from "../../assets/portfolio/bulldog.png";
import matchplicity2 from "../../assets/portfolio/bulldog2.png";

import Davidbanner1 from "../../assets/portfolio/Davidbanner-1.jpg";
import Davidbanner2 from "../../assets/portfolio/Davidbanner-2.jpg";
import mountain1 from "../../assets/portfolio/Mountain-1.PNG";
import mountain2 from "../../assets/portfolio/Mountain-1.PNG";
import bulkbrother1 from "../../assets/portfolio/Bulk.png";
import bulkbrother2 from "../../assets/portfolio/Bulk 2.png";
import eee1 from "../../assets/portfolio/eee1.png";
import eee2 from "../../assets/portfolio/eee2.png";
import ks from "../../assets/portfolio/KScompleteauto.png";

import foodie from "../../assets/portfolio/logos/foodie.svg";
import car from "../../assets/portfolio/logos/car.svg";
import match from "../../assets/portfolio/logos/bulldog-logo.svg";
import iviry from "../../assets/portfolio/logos/iviry.svg";
import mountain from "../../assets/portfolio/logos/mountain.svg";
import eeelogo from "../../assets/portfolio/logos/logoeee.webp";
import david from "../../assets/portfolio/logos/david.svg";
import bulkbrother from "../../assets/portfolio/logos/bulkbrother.svg";
import selectheallogo from "../../assets/selectheal-logo.webp"
import selectheal1 from "../../assets/select-heal-main-page.png"
import selectheal2 from "../../assets/select-heal-page-2.png"
import logo1890 from "../../assets/1890-logo.svg"
import foundation1890 from "../../assets/1890-1.jpg"
import foundation1890Two from "../../assets/1890-2.jpg"
import tennesselogo from "../../assets/tenesselogo.png"
import tennsse1 from "../../assets/tennese-1.jpg"
import tennsse2 from "../../assets/tennese-2.jpg"

import VideoOne from "./Videos/VideoOne";
import VideoTwo from "./Videos/VideoTwo";
import VideoThree from "./Videos/VideoThree";

import "./projects.styles.scss";

export const projects = [
  {
    icon: selectheallogo,
    id: "bulldog-estate",
    className: "horizontal",
    images: [selectheal1, selectheal2],
    description:
      "Our team of experienced developers can help you create unique, powerful websites that will showcase your business in the best light.",
    subtext:
      "We will create a website that is visually appealing and user friendly, making it easier for customers to find you and interact with your business.Our developers use the latest coding languages and platforms to ensure your website is fast, secure, and up-to-date.",
  },
  {
    icon: logo1890,
    id: "bulldog-estate",
    className: "horizontal",
    images: [foundation1890, foundation1890Two],
    description:
      "Our team of experienced developers can help you create unique, powerful websites that will showcase your business in the best light.",
    subtext:
      "We will create a website that is visually appealing and user friendly, making it easier for customers to find you and interact with your business.Our developers use the latest coding languages and platforms to ensure your website is fast, secure, and up-to-date.",
  },
  {
    icon: tennesselogo,
    id: "bulldog-estate",
    className: "horizontal",
    images: [tennsse1, tennsse2],
    description:
      "Our team of experienced developers can help you create unique, powerful websites that will showcase your business in the best light.",
    subtext:
      "We will create a website that is visually appealing and user friendly, making it easier for customers to find you and interact with your business.Our developers use the latest coding languages and platforms to ensure your website is fast, secure, and up-to-date.",
  },
 
  {
    icon: match,
    id: "bulldog-estate",
    className: "horizontal",
    images: [matchplicity1, matchplicity2],
    description:
      "Our team of experienced developers can help you create unique, powerful websites that will showcase your business in the best light.",
    subtext:
      "We will create a website that is visually appealing and user friendly, making it easier for customers to find you and interact with your business.Our developers use the latest coding languages and platforms to ensure your website is fast, secure, and up-to-date.",
  },
  {
    icon: bulkbrother,
    id: "bulk-brother",
    className: "horizontal2",
    images: [bulkbrother1, bulkbrother2],
    description:
      "Our team of experienced developers can help you create unique, powerful websites that will showcase your business in the best light.",
    subtext:
      "We will create a website that is visually appealing and user friendly, making it easier for customers to find you and interact with your business.Our developers use the latest coding languages and platforms to ensure your website is fast, secure, and up-to-date.",
  },
  {
    selected: true,
    icon: eeelogo,
    id: "eee-corp",
    className: "horizontal3",
    images: [eee1, eee2],
    description:
      "Our team of experienced developers can help you create unique, powerful websites that will showcase your business in the best light.",
    subtext:
      "We will create a website that is visually appealing and user friendly, making it easier for customers to find you and interact with your business.Our developers use the latest coding languages and platforms to ensure your website is fast, secure, and up-to-date.",
  },
  {
    icon: david,
    id: "david-starbuck-smith",
    className: "horizontal",
    images: [Davidbanner1, Davidbanner2],
    description:
      "Our team of experienced developers can help you create unique, powerful websites that will showcase your business in the best light.",
    subtext:
      "We will create a website that is visually appealing and user friendly, making it easier for customers to find you and interact with your business.Our developers use the latest coding languages and platforms to ensure your website is fast, secure, and up-to-date.",
  },
  {
    selected: true,
    className: "vertical",
    id: "iviry",
    icon: iviry,
    images: [Iviry1, Iviry2],
    description:
      "Our team of experienced developers can help you create unique, powerful websites that will showcase your business in the best light.",
    subtext:
      "We will create a website that is visually appealing and user friendly, making it easier for customers to find you and interact with your business.Our developers use the latest coding languages and platforms to ensure your website is fast, secure, and up-to-date.",
  },
  {
    selected: true,
    id: "foodie-not-a-chef",
    className: "vertical",
    icon: foodie,
    images: [foodie1, foodie2],
    description:
      "Our team of experienced developers can help you create unique, powerful websites that will showcase your business in the best light.",
    subtext:
      "We will create a website that is visually appealing and user friendly, making it easier for customers to find you and interact with your business.Our developers use the latest coding languages and platforms to ensure your website is fast, secure, and up-to-date.",
  },
  

  {
    id: "ks-complete-auto-inc",
    className: "vertical",
    images: [ks],
    icon: car,
    description:
      "Our team of experienced developers can help you create unique, powerful websites that will showcase your business in the best light.",
    subtext:
      "We will create a website that is visually appealing and user friendly, making it easier for customers to find you and interact with your business.Our developers use the latest coding languages and platforms to ensure your website is fast, secure, and up-to-date.",
  },
 

];

const Projects = () => {
  const navigate = useNavigate();
  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleRedirect = (name, i) => {
    if (i === 1) {
      setSelectedVideo(<VideoOne />);
    } else if (i === 2) {
      setSelectedVideo(<VideoTwo />);
    } else if (i === 3) {
      setSelectedVideo(<VideoThree />);
    } else {
      navigate(`/our-work/${name}/${i}`);
      setSelectedVideo(null); 
    }
  };

  return (
    <div className="projects-component">
      <div className="row">
        {projects.map((project, i) => (
          <div
            className="col-lg-4 service-container"
            key={i}
            onClick={() => handleRedirect(project.id, i + 1)}
          >
            <img className="icon" src={project.icon} alt="" />
            <div className={`service-image ${project.className}`}>
              {project.images.map((x, index) => (
                <img
                  key={index}
                  className={project.images.length > 1 ? "multiple" : "single"}
                  src={x}
                  alt=""
                />
              ))}
            </div>
          </div>
        ))}
      </div>
      {selectedVideo}
    </div>
  );
};

export default Projects;