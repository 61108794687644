import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./video-gallery.css";

import { videos } from "./videosData";

import forwardarrow from "../../../assets/Revenue-makers/foward-arrow.svg";
import backarrow from "../../../assets/Revenue-makers/back-arrow.svg";
import playvideoicon from "../../../assets/Revenue-makers/gridicons_play.svg";

export default function VideoGallery() {
  const [selectedCategory, setSelectedCategory] = useState("All"); // State for filtering
  const [currentPage, setCurrentPage] = useState(1);
  const [keyword, setKeyword] = useState(""); // State for pagination
  const videosPerPage = 12; // Videos per page

  // Function to filter videos based on selected category
  const filteredVideos = videos.filter((video) => {
    const categoryMatch = selectedCategory === "All" || video.category === selectedCategory;
    const keywordMatch = video.title
      .toLowerCase()
      .includes(keyword.toLowerCase());
      // console.log(categoryMatch)
    return categoryMatch && keywordMatch;
  });

  console.log("filteredvidoes on videogallery page",filteredVideos)

  // Reset currentPage to 1 when a new search is made or category is changed
  useEffect(() => {
    setCurrentPage(1);
  }, [keyword, selectedCategory]);

  // Apply pagination after filtering
  const indexOfLastVideo = currentPage * videosPerPage;
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
  const currentVideos = filteredVideos.slice(
    indexOfFirstVideo,
    indexOfLastVideo
  );

  const navigate = useNavigate();

  //   // Function to paginate the videos
  //   const indexOfLastVideo = currentPage * videosPerPage;
  //   const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
  //   const currentVideos = filteredVideos.slice(
  //     indexOfFirstVideo,
  //     indexOfLastVideo
  //   );

  // Calculate total pages for pagination
  const totalPages = Math.ceil(filteredVideos.length / videosPerPage);

  // Function to handle forward pagination
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Function to handle backward pagination
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="videos-pagination-parent">
      <div className="videos-pagination-child">
        <div className="videos-pagination-content">
          <h1 className="heading-Transformation revenue-heading">
          Explore {" "}<span className="goldcolor">MOJO News</span>
          </h1>
          <div className="inputs-div">
            {/* Keyword Search Input */}
            <input
              type="text"
              className="keyword-search"
              placeholder="Keyword Search"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)} // Update keyword state on input change
            />

            {/* Select Dropdown for Category */}
            <select
              onChange={(e) => setSelectedCategory(e.target.value)}
              value={selectedCategory}
              className="video-filter-dropdown"
            >
              <option value="All">Categories: All</option>
              <option value="Revenue Generated">Revenue Generated</option>
              <option value="Revenue Operations">Revenue Operations</option>
            </select>
          </div>

          {/* Display Video Thumbnails */}
          <div className="video-grid">
            {currentVideos.map((video) => (
              <div key={video.id} className="video-thumbnail">
                {/* Black overlay */}
                <div
                  className="overlay"
                  onClick={() => {
                    // console.log("Thumbnail", video.id);
                    navigate(`/${video.title.replace(/\s+/g, "-")}`);
                  }}
                ></div>
                <img
                  src={video.thumbnail}
                  alt={video.title}
                  className="podcast-thumbnail"

                  //   onClick={() => window.open(video.videoUrl)}
                />
                <div className="thumbnail-text-div">
                  <img
                    src={playvideoicon}
                    alt="playvideoicon"
                    className="playvideoicon"
                  />
                  <p className="video-description">{video.title}</p>
                </div>
              </div>
            ))}
          </div>

          {/* Pagination Controls */}
          <div className="pagination">
            <img
              src={backarrow}
              alt={forwardarrow}
              onClick={handlePreviousPage}
              className={currentPage === 1 ? "" : "pagination-btn"}
            />

            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => setCurrentPage(index + 1)}
                className={currentPage === index + 1 ? "active" : "in-active"}
              >
                {index + 1}
              </button>
            ))}
            <img
              src={forwardarrow}
              alt={forwardarrow}
              onClick={handleNextPage}
              className={currentPage === totalPages ? "" : "pagination-btn"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

// // Function to play the video (add your logic here)
// function playVideo(url) {
// console.log("Playing video:", url);
// }
