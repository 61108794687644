import React from "react";
import "./hero.css";

import arrow from "../../../assets/newpage/arrow.svg";
import Navbar from "../../../components/navbar/navbarcopy";
import logo from "../../../assets/home/logo.svg";

const Hero = () => {
  return (
    <>
      <div className="hero-parent">
        <Navbar />

        <div className="  main ">
          <video autoPlay loop muted playsInline className="background-video">
            <source src="/hero.mp4" type="video/mp4" />
            {/* Add additional source tags for other video formats if needed */}
            Your browser does not support the video tag.
          </video>

          <div className="overlayhero"></div>
          <div className="gradient-bottom-hero"></div>

          <div
            className=" container content"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <div className="newpage-mob-logo-header">
              <img className="newpage-mob-logo-header-img" src={logo} alt="" />
            </div>

            <h1 className="heading-hero text-center ">
              Welcome to Innovative MOJO
            </h1>
            <h1 className="sub-heading-hero text-center ">
              Where SEO Meets Brand Narratives<span className="mob-hidden">—</span><br className="mob-nextline" />The Perfect Collision for Success
            </h1>

            <p className="text-center d-flex justify-content-center para my-4">
              <span className="hero-para">
                At Innovative MOJO, we believe in the explosive power of
                combining SEO & Brand Spots. Just like the dynamic collision of
                two forces, our approach fuses the precision of SEO with the
                creativity of brand storytelling, creating a powerful impact
                that propels your brand forward. 
              </span>
            </p>

            <div className="d-flex justify-content-center gap-3 mr-3">
              <a href="/our-work">
                <button className="btncontact">See Our Work</button>
              </a>

              <a href="/booking">
                <button className="btncontact">
                  Lets Discuss
                  <img className="ml-2 button-arrow" src={arrow} alt="" />
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
