import React from "react";

import "./included.css";

import IncludedCards from "./IncludedCards";

import seo from "../../../assets/seo/seo.svg";
import search from "../../../assets/seo/search.svg";
import enhancement from "../../../assets/seo/enhancement.svg";
import content from "../../../assets/seo/content.svg";
import link from "../../../assets/seo/link.svg";
import analytics from "../../../assets/seo/analytics.svg";
const Included = () => {
  const seoItems = [
    "Comprehensive analysis of your website's current SEO performance.",
    "Identification of technical issues, keyword opportunities, and backlink profiles.",
    "A detailed report outlining actionable insights to enhance your SEO strategy.",
  ];

  const searchitems = [
    "Extensive keyword research tailored to your industry and target audience.",
    "    Optimization of on-page elements, including meta titles, descriptions, headings, and content, to align with high-converting keywords.",
    "    Ongoing monitoring and adjustment to keep up with search engine algorithm changes.",
  ];

  const enhancementItems = [
    " Optimization of site speed, mobile responsiveness, and overall user experience.",
    "Implementation of structured data and schema markup to enhance search engine understanding of your content.",
    "XML sitemap and robots.txt file optimization for better crawling and indexing.",
  ];

  const contentItems = [
    " Creation of SEO-friendly content that resonates with your audience and aligns with search intent.",
    "Blog posts, articles, and landing page copy crafted to engage visitors and boost rankings.",
    "Regular content updates to keep your website fresh and relevant.",
  ];

  const linkItems = [
    "  Strategic outreach and link-building campaigns to establish your site's authority.",
    "Development of high-quality backlinks from reputable sources to improve domain authority.",
    "Social media and local SEO integration to expand your reach and visibility.",
  ];

  const analyticsItems = [
    " Monthly performance reports with key metrics and insights.",
    " Analysis of traffic, rankings, and conversion data to measure success.",
    " Ongoing adjustments based on data-driven results to maximize ROI.",
  ];
  return (
    <div className="included-main-div">
      <h1 className="digitalheading  text-center">
        What’s <span className="digitaltitle">Included</span>
      </h1>
      <div className="included-flex-div">
        <IncludedCards
          image={seo}
          heading="In-Depth SEO Audit"
          listItems={seoItems}
        />
        <IncludedCards
          image={search}
          heading="Keyword Research & Optimization"
          listItems={searchitems}
        />
        <IncludedCards
          image={enhancement}
          heading="Technical SEO Enhancements"
          listItems={enhancementItems}
        />
        <IncludedCards
          image={content}
          heading="High-Quality Content Creation"
          listItems={contentItems}
        />
        <IncludedCards
          image={link}
          heading="Link Building & Off-Page SEO"
          listItems={linkItems}
        />
        <IncludedCards
          image={analytics}
          heading="Analytics & Reporting"
          listItems={analyticsItems}
        />
      </div>
    </div>
  );
};

export default Included;
