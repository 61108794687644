"use client";
import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Mousewheel,
} from "swiper/modules";
// import Button from "../ui/Button";
// import view from "../../assets/teambutton (1).png";
import view from "../../../assets/newpage/teambutton (1).png";
import digitalimg from "../../../assets/newpage/digitall.png";
import bggold from "../../../assets/newpage/digitalbg.png";

import Video3 from "../DigitalDirector/Video3";
import Video2 from "../DigitalDirector/Video2";
import Product1 from "../DigitalDirector/Product1";

import "./Ourteam.css";
const TeamSlider = () => {
  return (
    <>
      <Swiper
        className="TeamSlidermain"
        style={{
          "--swiper-pagination-color": "#8E7102",
          "--swiper-pagination-bullet-inactive-color": "#D9D9D9",
        }}
        modules={[
          Navigation,
          Pagination,
          Scrollbar,
          A11y,
          Autoplay,
          Mousewheel,
        ]}
        loop={true}
        spaceBetween={50}
        slidesPerView={1}
        speed={1500}
        autoplay={{
          delay: 2000,
        }}
        pagination={{ clickable: true }}
      >
        {/* 1 */}
        {/* <SwiperSlide>
          <div className="TeamSlidermain">
            <div className="team-content ">
              <h2 className="team-title text-center">Our Team:</h2>
              <h1 className="team-heading text-center">
                The Architects of Transformation
              </h1>
              <p className="team-para para mt-4 mb-4 text-center">
                At Innovative MOJO, we have assembled a team of passionate and
                experienced professionals who are dedicated to helping
                businesses thrive in the digital world. Our team consists of
                experts in SEO, graphic design, UI/UX design, website
                development, CRM implementation, lead generation, ad management,
                and content creation.
                <br />
                <br />
                With a combined experience of over 50 years in the industry, our
                team has a deep understanding of the ever-evolving digital
                landscape and how to leverage it for maximum results. We are
                constantly updating our skills and staying ahead of the curve to
                provide our clients with cutting-edge solutions
              </p>

              <div className="d-flex justify-content-center">
                <img className="team-view" src={view} alt="" />
              </div>
            </div>
          </div>
        </SwiperSlide> */}

        {/* 2 */}

        <SwiperSlide>
          <div className="d-flex justify-content-center position-relative overflow-hidden">
            <div className="digitalmain ">
              {/* <img className="digital-bg" src={bggold} alt="" /> */}
              {/* row1 */}
              <div
                className=" digital-row1 items-center"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              >
                {/* right */}
                <div className="digital-2nd-col  digital-director-mob-img">
                  <img className="digitalimg" src={digitalimg} alt="" />
                </div>

                {/* left */}
                <div className="digital-first-col">
                  <h2 className="digitaltitle">Your Digital Director</h2>
                  <p className="para digital-para">
                    Jay Ford is a visionary videographer whose cinematic
                    expertise has transformed brands like Dell, Fanatics, and
                    Intel. With a unique ability to blend creativity and
                    strategy, Jay brings stories to life, capturing the essence
                    of each brand in captivating visual narratives. Explore his
                    remarkable collaborations below and discover how his
                    experience can elevate your brand.
                  </p>
                </div>
                {/* right */}
                <div className="digital-2nd-col digital-director-desktop-img ">
                  <img className="digitalimg" src={digitalimg} alt="" />
                </div>
              </div>

              {/* row2 */}
              <div
                className=" digital-row2"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              >
                {/* col1 */}
                <Product1 />

                {/* col2 */}
                <Video3 />

                {/* col3 */}
                <Video2 />
              </div>
              {/* bottom */}

              {/* <Bottom/> */}
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default TeamSlider;
