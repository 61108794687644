import React from "react";

import Navbarr from "../../navbar/navbar.component";

import herosvg from "../../../assets/mojoFusion/hero.svg";
import arrwo from "../../../assets/seo/Arrow.svg";
const FusionHero = () => {
  return (
    <>
      <Navbarr />
      <div className="seo-parent">
        <div className="seo-parent-child">
          <div className="herosvg">
            <img src={herosvg} alt="hero svg" className="" />
          </div>
          <h1 className="heading-hero text-center ">MOJO Fusion</h1>
          <p className="seo-hero-para mob-px-5">
            Unleash the full potential of your brand with our MOJO Fusion
            Package, an all-encompassing solution that blends high-impact <br className="br-hidden" />
            videography, strategic SEO, and a custom-designed website. This
            package is designed to seamlessly fuse compelling storytelling <br className="br-hidden" /> with
            powerful digital strategies, providing you with everything you need
            to stand out online and connect with your audience. 
          </p>
          <div className="herosvg">
            <img src={arrwo} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default FusionHero;
