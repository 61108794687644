import React from 'react'

import "./seopage.stles.scss"
import SEOHero from '../../components/seoPage/HeroSection/SEOHero'
import Included from '../../components/seoPage/Included'
import Whychoose from '../../components/seoPage/WhyChoose'
import GetStarted from '../../components/seoPage/GetStarted'
import Footer from '../../components/footer/footer.component'

const SeoPage = () => {
  return (
    <div className='seo-main-page-div'>
      <div>
    <SEOHero/>
    <Included/>
    <Whychoose/>
    <GetStarted/>
    <div className="footer-component">
    <Footer/>
    </div>
    </div>
    </div>
  )
}

export default SeoPage