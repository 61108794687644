import React from 'react'
import RevenueHero from '../../components/revenue-growth/RevenueHero'
import PodcastSvgs from '../../components/revenue-growth/PodcastsSvgs'
import VideoGallery from '../../components/revenue-growth/VideoGallery'
import Footer from '../../components/footer/footer.component'

const RevenueGrowthpage = () => {
  return (
    <>
      <RevenueHero />
      {/* <PodcastSvgs /> */}
      <VideoGallery />
      <div className="footer-component">
        <Footer />
      </div>
    </>
  )
}

export default RevenueGrowthpage