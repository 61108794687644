import React from 'react'
import { useParams } from 'react-router-dom';
import { videos } from '../../components/revenue-growth/VideoGallery/videosData';

import Hero from '../../components/operations/Hero'
import "./operation.css";
import Footer from '../../components/footer/footer.component';
import Reading from '../../components/operations/Reading';
const Operations = () => {
  const { title } = useParams();  // Get the title from URL

  // Replace dashes with spaces to match the title in videos array
  const formattedTitle = title.replace(/-/g, ' ');

  // Find the video that matches the title
  const video = videos.find((video) => video.title === formattedTitle);
  // console.log("id on operations component",id);
  if (!video) return <h1>Video not found</h1>;
  return (
    <div style={{background:"black"}}>
      <Hero/>
      <Reading/>
      <Footer/>
    </div>
  )
}

export default Operations
