import React from 'react'
// import workimg from "../../assets/work.png"
import workimg from "../../../assets/newpage/work.png"
import "./Slider.css";

const HeadingSlider = () => {
  return (
  <div className="slide-main mt-19"   data-aos="fade-up"
  data-aos-duration="1000"
  data-aos-easing="ease-in-out">
    <div className="work-img-parent">
      <img src={workimg} alt="" className='work-img'  />
    </div>
      <div className='slide-content'>
      <h1 className='slide-title goldcolor'>Recent Clients</h1>
      <h2 className='slide-head heading-Transformation'>Proudly Worked With</h2>
    </div>
  </div>
  )
}

export default HeadingSlider
