import React from "react";


import WhychooseCards from "../../seoPage/WhyChoose/WhychooseCards";

const MojoMinuteWhychoose = () => {
  return (
    <div className="whychoose-main">
      <div>
        <h1 className="digitalheading why-choose-heading text-center">
          Why Choose the{" "}
          <span className="digitaltitle">MOJO Minute Package</span> ?
        </h1>
        <div className="ChooseCards-main-div">
          <WhychooseCards
            heading="Quick Impact"
            description="Deliver your message powerfully in just a minute, perfect for capturing attention in today’s fast-paced digital world."
          />
          <WhychooseCards
            heading="Creative Excellence"
            description="Our team excels in storytelling, ensuring your brand is presented in the most engaging and memorable way."
          />
          <WhychooseCards
            heading="Tailored Approach"
            description="Every aspect of the video is customized to align with your brand and marketing goals."
          />
          <WhychooseCards
            heading="Versatility"
            description="Use your MOJO Minute across multiple platforms, from your website to social media, to maximize reach and engagement."
          />
        </div>
      </div>
    </div>
  );
};

export default MojoMinuteWhychoose;
