import React from "react";

// import "./Seohero.css";

import Navbarr from "../../navbar/navbar.component";

import herosvg from "../../../assets/Mojo-Minute/herosvg.svg";
import arrwo from "../../../assets/seo/Arrow.svg";
const MojoMinuteHero = () => {
  return (
    <>
      <Navbarr />
      <div className="seo-parent">
        <div className="seo-parent-child">
          <div className="herosvg">
            <img src={herosvg} alt="hero svg" className="hero-svg-image"/>
          </div>
          <h1 className="heading-hero text-center ">MOJO Minute</h1>
          <p className="seo-hero-para mob-px-5">
            Elevate your brand's story in just a minute with our MOJO Minute
            Package. This dynamic videography solution is crafted to deliver{" "}
            <br className="br-hidden" /> maximum impact in a concise and engaging format. Perfect for
            commercials, social media reels, and more, our MOJO Minute <br className="br-hidden" />{" "}
            captures your audience's attention and leaves a lasting
            impression—all in under 60 seconds.
          </p>
          <div className="herosvg">
            <img src={arrwo} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default MojoMinuteHero;
