import React from "react";
import bg from "../../../assets/seo/get-startedbg.png";

import Button from "../../newpage/ui/Button";

import "./getstarted.css"

const GetStarted = () => {
  return (
    <div>
      <div className="Unparalleled-main  get-started-main-div">
        <img className="Unparalleled-bg" src={bg} alt="" />
        <div
          className="Unparalleled-content mt-5"
        >
          <h1 className="digitalheading text-center get-started-heading">Get Started Today</h1>
          <p className="digital-para-b text-center get-started-para text-opac">
            Ready to take your website to the next level? Our SEO Mastery
            Package is the key to unlocking <br className="br-hidden" />
            higher search engine rankings and attracting the right audience to
            your business. Contact us <br className="br-hidden"/>
            today to get started on your path to SEO success!
          </p>
          <div className="d-flex justify-content-center">
            <Button text="Lets Discuss" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
