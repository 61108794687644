import { useState, useEffect } from "react";
import { Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../assets/home/logo.svg";
import PrimaryButton from "../button-primary/button-primary.component";
import toggler from "../../assets/home/toggler.svg";
import { IoClose } from "react-icons/io5";
import { FaChevronDown } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import "./navbar.styles.scss";
const Navbarr = () => {
  const navigate = useNavigate();
  const [showNav, setShowNav] = useState(false);
  const [showServicesSub, setshowServicesSub] = useState(false);

  const ToggleServicesSubLinks = () => {
    setshowServicesSub(!showServicesSub);
  };

  useEffect(() => {
    if (showNav) {
      document.body.style.overflow = "hidden"; 
      document.body.style.position = "fixed";
    } else {
      document.body.style.overflow = ""; 
      document.body.style.position = "relative";  
    }
    
    return () => {
      document.body.style.overflow = "";
    };
  }, [showNav]);
  return (
    <div className="navbar-mobile-desktop">
      <div className="navbar-desktop">
        <Navbar expand="lg">
          <Container>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Brand onClick={() => navigate("/")}>
              <img src={logo} alt="" />
            </Navbar.Brand>
            <Navbar.Collapse
              id="navbarScroll"
              className="justify-content-between"
            >
              <Nav className="mx-auto">
                <Nav.Link onClick={() => navigate("/")}>Home</Nav.Link>
                {/* <Nav.Link onClick={() => navigate("/services")}>
                  Services
                </Nav.Link> */}
                <Nav.Link onClick={() => navigate("our-work")}>
                Our Work
                </Nav.Link>
                {/* <Nav.Link onClick={() => navigate("/about")}>About</Nav.Link> */}
                {/* <Nav.Link onClick={() => navigate("/faq")}>FAQs</Nav.Link> */}
                <Nav.Link onClick={() => navigate("/mojo-news")}>
                MOJO News
                </Nav.Link>
                <Nav.Link onClick={() => navigate("/faq")}>
                Faq
                </Nav.Link>
                {/* <Nav.Link onClick={() => navigate("/contact")}>
                 Contact Us
                </Nav.Link> */}
                <div class="dropdown">
                  <button class="dropbtn">Services</button>
                  <div class="dropdown-content">
                    <a href="mojo-minute" className="border-link">Mojo Minute</a>
                    <a href="/seo" className="border-link">SEO Mastery</a>
                    <a href="mojo-fusion" className="mojo-fusion-link">Mojo Fusion</a>
                  </div>
                </div>
              </Nav>
              <div className="phone-btn-div">
                <Nav.Link href="tel:+1 213 684 5444" className="phone-div">
                  <div className="phone-icon-text-div">
                    <FaPhoneAlt className="phone-icon" />
                    <p className="nav-phone">213 684 5444</p>
                  </div>
                </Nav.Link>
                <Nav.Link href="/booking">
                  <PrimaryButton>Lets Discuss</PrimaryButton>
                </Nav.Link>
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <div className="navbar-mobile">
      {showNav ? (
  
    <div  onClick={() => setShowNav(!showNav)} className="toggler">
      <IoClose className="mob-menu-close-icon" />
      </div>
  ) : (
    <div className="mob-menu-phone-div">
    <img
      className="toggler"
      onClick={() => setShowNav(!showNav)}
      src={toggler}
      alt=""
    />

    <Nav.Link href="tel:+1 213 684 5444" className="phone-div">
      <div className="phone-icon-text-div">
        <FaPhoneAlt className="phone-icon" />
        <p className="nav-phone">213 684 5444</p>
      </div>
    </Nav.Link>
  </div>
  )}
        {showNav && (
          <nav className="sidebar-nav">
            <div onClick={() => navigate("/")}>
              <img src={logo} alt="" />
            </div>
            <ul>
              {/* <li onClick={() => navigate("/services")}>
                <span>Services</span>
              </li> */}

              <li onClick={() => navigate("/")}>
                <span>Home</span>
              </li>

              <li onClick={() => navigate("/our-work")}>
                <span>Our Work</span>
              </li>
              <li onClick={() => navigate("/mojo-news")}>
                <span>MOJO News</span>
              </li>
              <li onClick={() => navigate("/FAQ")}>
                <span> Faq</span>
              </li>
              <div className="dropdown">
                <button className="dropbtn" onClick={ToggleServicesSubLinks}>
                  <div className="dropbtn-icon-div">
                  Services{" "}
                  <FaChevronDown
                    className={showServicesSub ? "rotate" : "rotate-down"}
                  />
                  </div>
                </button>
                <div
                  className={`ServicesLink ${showServicesSub ? "open" : ""}`}
                >
                  <a href="/mojo-minute" className="border-link">
                    Mojo Minute
                  </a>
                  <a href="/seo" className="border-link">
                    SEO Mastery
                  </a>
                  <a href="/mojo-fusion" className="mojo-fusion-link">
                    Mojo Fusion
                  </a>
                </div>
              </div>
              {/* <li onClick={() => navigate("/about")}>
                <span>About</span>
              </li>
              <li onClick={() => navigate("/contact")}>
                <span>Contact Us</span>
              </li>

              <li onClick={() => navigate("/faq")}>
                <span>FAQ</span>
              </li> */}
               {/* <li>
              <Nav.Link href="tel:+1 213 684 5444" >
                  <div className="phone-icon-text-div">
                    <FaPhoneAlt className="phone-icon" />
                    <p className="nav-phone">+1 213 684 5444</p>
                  </div>
                </Nav.Link>
                </li> */}
            </ul>
            <div>
            <div className="phone-btn-div">
                {/* <Nav.Link href="tel:+1 213 684 5444" className="phone-div">
                  <div className="phone-icon-text-div">
                    <FaPhoneAlt className="phone-icon" />
                    <p className="nav-phone">213 684 5444</p>
                  </div>
                </Nav.Link> */}
                <Nav.Link href="/booking">
                  <PrimaryButton>Lets Discuss</PrimaryButton>
                </Nav.Link>
              </div>
            </div>
          </nav>
        )}
      </div>
    </div>
  );
};

export default Navbarr;
