import React from "react";
import "./SeoNewDesign.css";
import "./pricing.css";
import { TiTick } from "react-icons/ti";
import car1img from "../../../assets/newpage/seonewdesign/1.png";
import car2img from "../../../assets/newpage/seonewdesign/2.png";
import car3img from "../../../assets/newpage/seonewdesign/3.png";
import car4img from "../../../assets/newpage/seonewdesign/4.png";
import car5img from "../../../assets/newpage/seonewdesign/5.png";
import car6img from "../../../assets/newpage/seonewdesign/6.png";
import car7img from "../../../assets/newpage/seonewdesign/7.png";
import car8img from "../../../assets/newpage/seonewdesign/8.png";
import car9img from "../../../assets/newpage/seonewdesign/9.png";
import car10img from "../../../assets/newpage/seonewdesign/10.png";
import starter from "../../../assets/newpage/Camera.svg";
import premium from "../../../assets/newpage/premium.png";
import ultimate from "../../../assets/newpage/Ultimate.png";

import bl from "../../../assets/newpage/seonewdesign/bl.png";
import br from "../../../assets/newpage/seonewdesign/br.png";

import SewNewContent from "./SewNewContent";

const SeoNewDesign = () => {
  return (
    <div className="newseo">
      {/* bg */}
      <img className="seonew-bl-img" src={bl} alt="" />
      <img className="seonew-br-img" src={br} alt="" />

      <div className="newseo-main">
        {/* row 1 */}
        <div className="newseo-row1">
          {/* card1 */}
          <div className="pricing-main">
            <div className="pricing-bg-color"></div>
            <div className="pricing-content">
              <h1 className="pricing-heading">MOJO Minute</h1>
              <p className="pricing-point-tagline">
              A dynamic 45-60 second commercial here to boost your brand’s visibility and connect with your audience on social media.
                </p>
             <div className="pricing-icon">
              <img src={starter} alt="" width={95} height={90} />
             </div>

              {/* <h2 className="pricing-price">
                <span className="pricing-dollar">$</span>30
                <span className="pricing-time">/month</span>
              </h2> */}

              <div className="pricing-points">
                <p className="whitetext">
                  <TiTick className="tick-icon" />
                </p>
                <p className="pricing-point-content">
                  Concept Development: Tailored storytelling strategy to capture
                  your brand’s essence.{" "}
                </p>
              </div>
              <div className="pricing-points">
                <p className="whitetext">
                  <TiTick className="tick-icon" />
                </p>
                <p className="pricing-point-content">
                  Professional Videography: 1 fully produced and edited video
                  (45-60 seconds each).
                </p>
              </div>
              {/* <div className="pricing-points">
                <p className="whitetext">
                  <TiTick className="tick-icon" />
                </p>
                <p className="pricing-point-content">
                  Social Media Content: 5 Shorter videos (15-30 sec each)
                </p>
              </div> */}
              <div className="pricing-points">
                <p className="whitetext">
                  <TiTick className="tick-icon" />
                </p>
                <p className="pricing-point-content">
                  Scriptwriting: Engaging scripts crafted to resonate with your
                  audience.
                </p>
              </div>
              <div className="pricing-points">
                <p className="whitetext">
                  <TiTick className="tick-icon" />
                </p>
                <p className="pricing-point-content">
                  Motion Graphics: Custom animations to enhance your brand
                  message.
                </p>
              </div>
              <div className="pricing-points">
                <p className="whitetext">
                  <TiTick className="tick-icon" />
                </p>
                <p className="pricing-point-content">
                  {" "}
                  Dedicated Project Manager: Personalized attention to ensure
                  your vision comes to life.{" "}
                </p>
              </div>
              <div className="pricing-points">
                <p className="whitetext">
                  <TiTick className="tick-icon" />
                </p>
                <p className="pricing-point-content">
                  {" "}
                  Ideal For: Brands looking to make a powerful, lasting
                  impression through high-quality video content.
                </p>
              </div>
              <div className="pricing-points-main">
                <p className="pricing-points-main goldseocolor">
                  {" "}
                  Bonus - five engaging 15-second reels to connect with your audience on social media.
                </p>
              </div>

              <div  style={{ display: "flex", justifyContent: "center",marginTop:"60px",  }}>
                <a href="/mojo-minute" style={{position:"absolute", bottom:"30px"}}>
                  <button className="btncontact ml-2">Learn More</button>
                </a>
              </div>
            </div>
          </div>

          {/* card2 */}
          <div className="pricing-main">
            <div className="pricing-bg-color"></div>
            <div className="pricing-content">
              <h1 className="pricing-heading">SEO Mastery  </h1>
 <p className="pricing-point-tagline">
 Elevate your brand's online presence with expert SEO strategies. 
                </p>
              <div className="pricing-icon">
              <img src={premium} alt="" width={70} height={70} />
             </div>

              {/* <h2 className="pricing-price">
                <span className="pricing-dollar">$</span>30
                <span className="pricing-time">/month</span>
              </h2> */}

              <div className="pricing-points">
                <p className="whitetext">
                  <TiTick className="tick-icon" />
                </p>
                <p className="pricing-point-content">
                Comprehensive Site Audit: In-depth analysis of your website’s SEO performance.
                </p>
              </div>
              <div className="pricing-points">
                <p className="whitetext">
                  <TiTick className="tick-icon" />
                </p>
                <p className="pricing-point-content">
                Keyword Research & Optimization: Targeted strategies to rank higher on search
engines.
                </p>
              </div>
              <div className="pricing-points">
                <p className="whitetext">
                  <TiTick className="tick-icon" />
                </p>
                <p className="pricing-point-content">
                Technical SEO Enhancements: Ensure your website is fully optimized for speed,
                mobile-friendliness, and user experience.                </p>
              </div>
              <div className="pricing-points">
                <p className="whitetext">
                  <TiTick className="tick-icon" />
                </p>
                <p className="pricing-point-content">
                Content Strategy & Creation: Monthly blog posts and content updates designed to
                attract and retain your target audience.
                </p>
              </div>
              <div className="pricing-points">
                <p className="whitetext">
                  <TiTick className="tick-icon" />
                </p>
                <p className="pricing-point-content">
                Monthly Reporting: Detailed reports on performance, keyword rankings, and traffic
                improvements.
                </p>
              </div>
              <div className="pricing-points">
                <p className="whitetext">
                  <TiTick className="tick-icon" />
                </p>
                <p className="pricing-point-content">
                  {" "}
                  Ongoing Support: Continuous adjustments to keep your SEO strategy ahead of the
                  competition.
                </p>
              </div>
              <div className="pricing-points">
                <p className="whitetext">
                  <TiTick className="tick-icon" />
                </p>
                <p className="pricing-point-content">
                  {" "}
                  Ideal For: Businesses committed to long-term growth and increased online visibility.
                </p>
              </div>
              <div  style={{ display: "flex", justifyContent: "center",marginTop:"60px",  }}>
                <a href="/seo" style={{position:"absolute", bottom:"30px"}}>
                  <button className="btncontact ml-2">Learn More</button>
                </a>
              </div>
            </div>
          </div>
          {/* card3 */}
          <div className="pricing-main">
            <div className="pricing-bg-color"></div>
            <div className="pricing-content">
              <h1 className="pricing-heading">MOJO Fusion</h1>
              <p className="pricing-point-tagline">
              Combining the power of storytelling with SEO expertise.
                </p>
              <div className="pricing-icon">
              <img src={ultimate} alt="" width={70} height={70} />
             </div>

              {/* <h2 className="pricing-price">
                <span className="pricing-dollar">$</span>30
                <span className="pricing-time">/month</span>
              </h2> */}

              <div className="pricing-points">
                <p className="whitetext">
                  <TiTick className="tick-icon" />
                </p>
                <p className="pricing-point-content">
                Everything in the Brand Storytelling Package: Concept development, videography,
                scriptwriting, motion graphics, and project management.
                </p>
              </div>
              <div className="pricing-points">
                <p className="whitetext">
                  <TiTick className="tick-icon" />
                </p>
                <p className="pricing-point-content">
                We come to you: We will fly to anywhere in the world to capture your story
                </p>
              </div>
              <div className="pricing-points">
                <p className="whitetext">
                  <TiTick className="tick-icon" />
                </p>
                <p className="pricing-point-content">
                Everything in the SEO Mastery Package: Site audit, keyword optimization, technical
                SEO, content strategy, monthly reporting, and support.
                </p>
              </div>
              <div className="pricing-points">
                <p className="whitetext">
                  <TiTick className="tick-icon" />
                </p>
                <p className="pricing-point-content">
                Integrated Strategy: A seamless blend of video storytelling and SEO efforts to
                maximize impact.
                </p>
              </div>
              <div className="pricing-points">
                <p className="whitetext">
                  <TiTick className="tick-icon" />
                </p>
                <p className="pricing-point-content">
                Quarterly Campaigns: Coordinated video and SEO campaigns, launched quarterly, to
                keep your brand fresh and top of mind.
                </p>
              </div>
              <div className="pricing-points">
                <p className="whitetext">
                  <TiTick className="tick-icon" />
                </p>
                <p className="pricing-point-content">
                  {" "}
                  Priority Support: Top-tier access to our creative and technical teams for any needs.
                </p>
              </div>
              <div className="pricing-points">
                <p className="whitetext">
                  <TiTick className="tick-icon" />
                </p>
                <p className="pricing-point-content">
                  {" "}
                  Ideal For: Brands ready to dominate their industry with a comprehensive approach to online
presence and engagement.
                </p>
              </div>
              <div className="pricing-points">
              
                <p className="pricing-points-main goldseocolor">
                  {" "}
                  Bonus: Free premium website
                </p>
              </div>

              <div  style={{ display: "flex", justifyContent: "center",marginTop:"60px",  }}>
                <a href="/mojo-fusion" style={{position:"absolute", bottom:"30px"}}>
                  <button className="btncontact ml-2">Learn More</button>
                </a>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  );
};

export default SeoNewDesign;
