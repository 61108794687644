import React from "react";

import "./included.css";

import IncludedCards from "../../seoPage/Included/IncludedCards";

import consultation from "../../../assets/Mojo-Minute/consulatation.svg";
import delivery from "../../../assets/Mojo-Minute/delivery.svg";
import filming from "../../../assets/Mojo-Minute/filming.svg";
import production from "../../../assets/Mojo-Minute/production.svg";
import script from "../../../assets/Mojo-Minute/script.svg";
import vision from "../../../assets/Mojo-Minute/vision-board.svg";
import additionalsvg from "../../../assets/Mojo-Minute/additional.svg";

const MojoMinuteIncluded = () => {
  const consultationItems = [
    "We kick off with a personalized consultation to understand your brand, objectives, and target audience.",
    "Our creative team collaborates with you to develop a compelling concept that aligns with your brand's identity.",
    "We explore innovative ideas to make your 45 to 60-second video both memorable and effective.",
  ];

  const scriptItems = [
    "Our expert scriptwriters craft a concise yet impactful script that conveys your message with clarity and creativity.",
    " We create a detailed storyboard to visualize the flow and key scenes of the video.",
    "Your input is invaluable—review and refine the script and storyboard to ensure the final product matches your vision.",
  ];

  const visionItems = [
    "We present a vision board showcasing the look and feel of your video, including style, color palette, and overall aesthetic.",
    "This step ensures that every visual element aligns perfectly with your brand’s personality and goals.",
    "We welcome your feedback to fine-tune the visual direction before filming begins.",
  ];

  const filmingItems = [
    "Our professional videographers bring your vision to life using state-of-the-art equipment.",
    "We focus on capturing the essence of your brand in a short, high-impact video.",
    "From stunning visuals to crystal-clear audio, we ensure every frame contributes to the story.",
  ];

  const productionItems = [
    "Our skilled editors craft your footage into a seamless, polished final cut.",
    "We add music, graphics, and any special effects needed to enhance the video and make it truly stand out.",
    "A draft is provided for your review, with revisions made to guarantee your complete satisfaction.",
  ];

  const deliveryItems = [
    "The final video is delivered in a format optimized for your preferred platforms, including social media, websites, and more.",
    "We offer guidance on how to maximize the impact of your MOJO Minute across different channels.",
    "Our team remains available for any follow-up support or additional edits.",
  ];
  return (
    <div className="included-main-div">
      <h1 className="digitalheading   text-center">
        What’s <span className="digitaltitle heading-hero">Included</span>
      </h1>
      <div className="included-flex-div pb-15">
        <IncludedCards
          image={consultation}
          heading="Consultation & Concept Development"
          listItems={consultationItems}
        />
        <IncludedCards
          image={script}
          heading="Scriptwriting & Storyboarding"
          listItems={scriptItems}
        />
        <IncludedCards
          image={vision}
          heading="Vision Board & Style Presentation"
          listItems={visionItems}
        />
        <IncludedCards
          image={filming}
          heading="Filming & Production"
          listItems={filmingItems}
        />
        <IncludedCards
          image={production}
          heading="Post-Production & Editing"
          listItems={productionItems}
        />
        <IncludedCards
          image={delivery}
          heading="Final Delivery & Optimization"
          listItems={deliveryItems}
        />
      </div>

      <div className="additional-feature">
        <div className="additional-feature-content">
          <div className="included-cards-image-div">
          <img src={additionalsvg} alt="additional feature" />
          </div>
          <h2 className="included-cards-heading">Additional Features:</h2>
          <ul>
            <li className="additional-feature-li">
              <span className="included-cards-list-span">
                45 to 60-Second Commercial:
              </span> {" "}
              A compelling video that captures your brand's essence and
              communicates your message effectively.
            </li>
            <li className="additional-feature-li">
              <span className="included-cards-list-span">
                Five 15-Second Reels:
              </span>{" "}
              Perfect for social media, these short, engaging reels offer a
              napshot of your brand in action.
            </li>
            <li className="additional-feature-li">
              <span className="included-cards-list-span">
                {" "}
                High-Quality Production:
              </span> {" "}
              From start to finish, we ensure top-tier quality that reflects the
              professionalism and uniqueness of your brand.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MojoMinuteIncluded;
