import React, { useState, useEffect } from "react";

import FAQ from "../../components/faq/faq.component";
import HomeFooter from "../../components/home-footer/home-footer.component";
import AboutSection from "../../components/about-section/about-section.component";
import BannerSection from "../../components/banner-section/banner-section.component";
import BenefitsSection from "../../components/benefits-section/benefits-section.component";
import ServicesSection from "../../components/services-section/services-section.component";
import TestimonialSection from "../../components/testimonial-section/testimonial-section.component";
import { Helmet } from "react-helmet";

import BackgroundEffect from "../../components/background-effect/background-effect.component";
import HomeWrapper from "../../components/home-wrapper/home-wrapper.component";

import "./home.styles.scss";
const Home = () => {
  const mobile = window.innerWidth <= 767;
  const [loaded, setLoaded] = useState(true);
  const [text, setText] = useState(-1);

  useEffect(() => {
    // Start shrinking after a delay.
    setTimeout(() => {
      setText(0);
    }, 180);
    setTimeout(() => {
      const interval = setInterval(() => {
        setText((prevText) => {
          return !prevText || prevText === 1 ? 1 : (prevText + 1) % 3;
        });
      }, 600);

      // Stop the interval after cycling through all texts.
      setTimeout(() => {
        clearInterval(interval);

        setText(5);
      }, 2000);
      setTimeout(() => {
        setLoaded(true);
      }, 3200);
    }, 1000);
  }, []);

  useEffect(() => {
    // Check if the animation has already been played for this session
    if (!sessionStorage.getItem("animationPlayed")) {
      setLoaded(false);
      sessionStorage.setItem("animationPlayed", "true");
    }
  }, []);

  return (
    <div className="home-container">
      <Helmet>
        <title>
          Innovative Mojo | Digital Marketing Company - Elevate Your Brand
          Today!
        </title>
        <meta
          name="description"
          content="Elevate your brand with Innovative Mojo, a leading digital marketing company offering expert solutions in SEO, branding, video, and more. Let us take your business to the next level!"
        />
      </Helmet>
      {loaded ? (
        <div className="fade-in">
          {!mobile ? (
            <div className="desktop-view">
              <HomeWrapper />
            </div>
          ) : (
            <div className="mobile-view">
              <BannerSection />
              <AboutSection />
              <ServicesSection />
              <BenefitsSection />
              <TestimonialSection />
              <FAQ />
              <HomeFooter />
            </div>
          )}
        </div>
      ) : (
        <div className="loading-screen">
          <BackgroundEffect />
          <div className="overlay square">
            <div className={`small-square ${text === 5 ? "animated" : ""}`}>
              {text === 0 && <span className="text1">FIND</span>}
              {text === 1 && <span className="text2">YOUR</span>}
              {text === 5 && <span className="text4">MOJO</span>}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
