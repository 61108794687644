import React from 'react'
import MojoMinuteHero from '../../components/mojoMinute/Hero'
import MojoMinuteIncluded from '../../components/mojoMinute/Included'
import CanLook from '../../components/mojoMinute/CanLook'
import MojoMinuteWhychoose from '../../components/mojoMinute/WhyChoose'
import GetMojoMinute from '../../components/mojoMinute/GetMojoMinute'
import Footer from '../../components/footer/footer.component'

const MojoMinutepage = () => {
  return (
    <div>
        <MojoMinuteHero />
        <CanLook />  
        <MojoMinuteIncluded />
        <MojoMinuteWhychoose />
        <GetMojoMinute />
        <div className="footer-component">
    <Footer/>
    </div>
    </div>
  )
}

export default MojoMinutepage