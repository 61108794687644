import React from "react";
import FusionHero from "../../components/mojoFusion/HeroSection";
import FusionIncluded from "../../components/mojoFusion/Included";
import FusionWhyChoose from "../../components/mojoFusion/WhyChoose";
import CanLook from "../../components/mojoMinute/CanLook";
import FusionGetStarted from "../../components/mojoFusion/GetStarted";
import Footer from "../../components/footer/footer.component";

const MojoFusionPage = () => {
  return (
    <>
      <FusionHero />
      <FusionIncluded />
      <FusionWhyChoose />
      <FusionGetStarted />
      <CanLook />
      <div className="footer-component">
        <Footer />
      </div>
    </>
  );
};

export default MojoFusionPage;
