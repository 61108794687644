import React from "react";

import bg from "../../../assets/seo/get-startedbg.png";

import Button from "../../newpage/ui/Button";

const GetMojoMinute = () => {
  return (
    <div>
      <div className="Unparalleled-main  get-started-main-div">
        <img className="Unparalleled-bg" src={bg} alt="" />
        <div className="Unparalleled-content mt-5">
          <h1 className="digitalheading text-center get-started-heading">
            Get Your MOJO Minute Today
          </h1>
          <p className="digital-para-b text-center get-started-para text-opac">
            Ready to make a big impact in just 60 seconds? Our MOJO Minute
            Package is the perfect way <br className="br-hidden" />
            to showcase your brand with creativity and flair. Contact us today
            to get started and discover <br className="br-hidden" />
            how we can bring your story to life in the most engaging way
            possible.
          </p>
          <div className="d-flex justify-content-center">
            <Button text="Lets Discuss" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetMojoMinute;
