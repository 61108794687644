import React from "react";
import "./Ourteam.css";

import TeamSlider from "./TeamSlider";


const Ourteam = () => {
  return (
    <div className="d-flex justify-content-center  teammain overflow-hidden"  
     data-aos="fade-up"
    data-aos-duration="1000"
    data-aos-easing="ease-in-out">
            <video autoPlay loop muted playsInline className="background-video">
        <source src="/glittering-gold-particles-on-a-black-background-an-2023-11-27-04-54-15-utc.mp4" type="video/mp4" />
        
        Your browser does not support the video tag.
      </video>
<div className="overlayourteam"></div>

   <div className="team-gradient"></div>
   <div className="team-gradient-bottom"></div>

   
 
      <TeamSlider/>
    </div>
  );
};

export default Ourteam;
