import React from "react";

import "./whychoose.css";

import WhychooseCards from "./WhychooseCards";

const Whychoose = () => {
  return (
    <div className="whychoose-main">
      <div>
        <h1 className="digitalheading why-choose-heading text-center">
          Why Choose Our{" "}
          <span className="digitaltitle">SEO Mastery Package?</span>
        </h1>
        <div className="ChooseCards-main-div">
          <WhychooseCards
            heading="Customized Strategies"
            description="Tailored SEO strategies designed to meet your unique business goals."
          />
          <WhychooseCards
            heading="Expert Team"
            description="A dedicated team of SEO specialists with years of experience."
          />
          <WhychooseCards
            heading="Results-Driven"
            description="A focus on delivering measurable results, from increased traffic to higher conversions."
          />
          <WhychooseCards
            heading="Transparent Communication"
            description="Regular updates and clear communication to keep you informed every step of the way."
          />
        </div>
      </div>
    </div>
  );
};

export default Whychoose;
