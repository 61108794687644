import React from "react";
import bg from "../../../assets/seo/get-startedbg.png";

import Button from "../../newpage/ui/Button";

// import "./getstarted.css"

const FusionGetStarted = () => {
  return (
    <div>
      <div className="Unparalleled-main  get-started-main-div">
        <img className="Unparalleled-bg" src={bg} alt="" />
        <div className="Unparalleled-content mt-5">
          <h1 className="digitalheading text-center get-started-heading">
            Get Started with MOJO Fusion Today
          </h1>
          <p className="digital-para-b text-center get-started-para text-opac">
            Ready to transform your brand's digital presence with an all-in-one
            solution? The MOJO Fusion <br className="br-hidden" />
            Package offers everything you need to captivate your audience, rank
            higher on search engines, <br className="br-hidden" />
            and showcase your brand with a stunning, SEO-optimized website.
            Contact us today to get <br className="br-hidden" />
            started and take advantage of this comprehensive package!
          </p>
          <div className="d-flex justify-content-center">
            <Button text="Lets Discuss" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FusionGetStarted;
